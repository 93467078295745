<template>
    <div id="app">
        <TopMenu @translate="showTranslate" :isactive="showTranslator"/>
        <Translator @on-country-click="showTranslate" v-if="showTranslator" :countries="countriesShow"/>
        <div class="mt-n3">
            <router-view />
        </div>
        <Footer/>
    </div>
</template>
<script>
import TopMenu from "@/components/TopMenu";
import Footer from "@/components/Footer";
import { Translator } from 'vue-google-translate';
export default {
    data(){
        return{
            showTranslator:false,
            toggle:false,
            countriesShow:[
            {
                code: 'en|en',
                title: 'English',
            },
            {
                code: 'en|es',
                title: 'Spanish',
            },
            ],
        }
    },
    components: {
        TopMenu,
        Footer,
        Translator,
    },
    methods:{
        showTranslate(){
            this.showTranslator = !this.showTranslator
        }
    },
    mounted() {
      const scripAnalytics = document.createElement('script');
      scripAnalytics.type = 'text/javascript';
      scripAnalytics.src = '/js/google-analytics.js';
      document.head.appendChild(scripAnalytics);
   }
};
</script>