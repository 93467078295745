<template>
    <div>
        <img v-if="url" class="w-100" :src="url" alt="img" />
        <img v-else class="w-100" :src="def" alt="img" />
        <div class="container">
            <h1 class="mt-5 pb-1 text-center">{{ title }}</h1>
        </div>
    </div>
</template>
<script>
export default {
    props: ["title", "url"],
    data() {
        return {
            def: require("@/assets/default.jpg"),
        };
    },
};
</script>
