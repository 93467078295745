
let videos = [
    {
        link: "https://www.youtube.com/embed/G6vSbZtF2s8?si=c-6Lp_QNHOJeqgZu",
        tilulo: "La Visita.",
    },
    {
        link: "https://www.youtube.com/embed/e13yy-kwArY?si=D_KoeY4eYORWcbPF",
        tilulo: "La Trampa.",
    },
    {
        link: "https://www.youtube.com/embed/LLY0T2OmfAE?si=o0us7eDxyUHvcFqT",
        tilulo: "Programa MOSCAMED, presente en Radio Sonora 96.9 Guatemala.",
    },
    {
        link: "https://www.youtube.com/embed/a9O1r0Ap6-w?si=x8T2lZbYF_HfBpd6",
        tilulo: "Programa MOSCAMED Guatemala. Los productores ganan más.",
    },
    {
        link: "https://www.youtube.com/embed/NIephMDiF1w?si=nuJgernTeviuE60p",
        tilulo: "Guatemala, fabrica de miel de calidad para el mundo.",
    },
    {
        link: "https://www.youtube.com/embed/NtclZWhTN9I?si=uWlZoDzslWV_pKLJ",
        tilulo: "Programa MOSCAMED Centro de Transferencia de Tecnología Apícola.",
    },
    {
        link: "https://www.youtube.com/embed/SG1cSJHHY0E?si=8qARC8C0NUpp6kNC",
        tilulo: "La Mosca del Mediterráneo.",
    },
    {
        link: "https://www.youtube.com/embed/p974nWxSX30?si=G1cnR3qIHxZQRzpE",
        tilulo: "Video Institucional Programa MOSCAMED.",
    },
    {
        link: "https://www.youtube.com/embed/QdrXFc2oYac?si=mbh0Qzx1Dl7okLGB",
        tilulo: "El técnico amigo.",
    },
    {
        link: "https://www.youtube.com/embed/iZ-RtZbRTFE?si=mbh0Qzx1Dl7okLGB",
        tilulo: "Que libera la avioneta del Programa MOSCAMED.",
    },
    {
        link: "https://www.youtube.com/embed/_4yaMqtI1C0?si=mbh0Qzx1Dl7okLGB",
        tilulo: "Video conmemorativo de los 49 años del Programa MOSCAMED.",
    },
    {
        link: "https://www.youtube.com/embed/PlY1vWGl91Q?si=6PmMYd4tyiNmsjrG",
        tilulo: "Frutas libres de moscas: Cómo aumentar la exportación desde Guatemala.",
    },
    {
        link: "https://www.youtube.com/embed/jTc7dpIIt7A?si=AZMglUARrNa84lHL",
        tilulo: "Cápsula 838. Mosca del Mediterráneo en Guatemala.",
    },
    {
        link: "https://www.youtube.com/embed/1dta68Y8oLQ",
        tilulo: "La detección por trampeo permite ubicar la plaga en su estado adulto.",
    },
    {
        link: "https://www.youtube.com/embed/c8n136-zfYY",
        tilulo: "Peten área libre de mosca del Mediterráneo.",
    },
    { link: "https://www.youtube.com/embed/C3_fXnmudkc", tilulo: "Apoyo al sector apícola." },
    { link: "https://www.youtube.com/embed/HPPQbNjp6fw", tilulo: "Apoyo a la Meliponicultura." },
    { link: "https://www.youtube.com/embed/NBPiVPtdfDU", tilulo: "Apicultura Moscamed nuestro diario." },
    {
        link: "https://www.youtube.com/embed/RQ-Wsd3lKp0",
        tilulo: "La detección por muestreo permite ubicar la plaga en su estado inmaduro",
    },
    {
        link: "https://www.youtube.com/embed/mUlvJ_tpfUE",
        tilulo: "El Programa Moscamed a través del Centro de Empaque de Adulto Frío, CEAF, realiza la liberación en campo de mosca del Mediterráneo estéril, reduciendo las poblaciones y generando Áreas Libres de la plaga en Guatemala",
    },
    {
        link: "https://www.youtube.com/embed/ZWNBi-IfhaA",
        tilulo: "Los Puestos de Cuarentena protegen el Area Libre de mosca del Mediterráneo con reconocimiento internacional de Petén",
    },
    { link: "https://www.youtube.com/embed/5dHtAwOLkOA", tilulo: "Moscamed Area Libre Petén" },
    {
        link: "https://www.youtube.com/embed/UpKebSVjIRg",
        tilulo: "La detección y control de la mosca del mediterraneo, MAM.",
    },
    {
        link: "https://www.youtube.com/embed/fnfl69Xu9tM",
        tilulo: "La detección y control de la mosca del mediterraneo, POPTI.",
    },
    { link: "https://www.youtube.com/embed/MHOBqccUauc", tilulo: "Moscamed AIEA (Español)" },
    { link: "https://www.youtube.com/embed/RTn2CWFdnCg", tilulo: "Moscamed AIEA (Inglés)" },
    { link: "https://www.youtube.com/embed/QCytMAL6NOI", tilulo: "Moscamed Natgeo (Inglés)" },
    { link: "https://www.youtube.com/embed/8wKUzh1tiaM", tilulo: "Programa Moscamed PIU Oct 2020" },
    { link: "https://www.youtube.com/embed/KdUXre_-vCo", tilulo: "Manejo adecuado del GPS" },
    { link: "https://www.youtube.com/embed/8Imm1nlhCLE", tilulo: "Trabajos multinacionales" },
    { link: "https://www.youtube.com/embed/LA4Z1u-uGZ0", tilulo: "Moscamed Visión Rural" },
    { link: "https://www.youtube.com/embed/1SncQ03XzwY", tilulo: "Producción y liberación" },
    { link: "https://www.youtube.com/embed/n1kuxO-1BL0", tilulo: "Curso intrarregional 2019" },
    { link: "https://www.youtube.com/embed/BlKe9570cIo", tilulo: "Curso intrarregional 2017" },
    { link: "https://www.youtube.com/embed/92wB9rtZSLk", tilulo: "Curso intrarregional 2015" },
    { link: "https://www.youtube.com/embed/ZuKg9wGW1uc", tilulo: "Centro apícola" },
];
export const videosMixin = {
    methods: {
        getVideos() {
            return videos;
        },
        lastVideo() {
            return videos[0];
        },
    },
};
