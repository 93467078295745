import Vue from "vue";
import VueRouter from "vue-router";
import Home from "../views/Home.vue";

Vue.use(VueRouter);

const routes = [
    {
        path: "/",
        name: "Home",
        component: Home,
    },
    //HOME
    {
        path: "/medfly",
        name: "APOYO A LA FRUTICULTURA",
        component: function () {
            return import("../views/Home/Medfly.vue");
        },
    },
    {
        path: "/apicultura",
        name: "Apoyo a la apicultura",
        component: function () {
            return import("../views/Home/Apicultura.vue");
        },
    },
    
    {
        path: "/tecnica",
        name: "ÁREAS DE TRABAJO EN GUATEMALA",
        component: function () {
            return import("../views/Home/Tecnica.vue");
        },
    },
    {
        path: "/fruticultura",
        name: "MOSCA DEL MEDITERRANEO",
        component: function () {
            return import("../views/Home/Fruticultura.vue");
        },
    },
    // QUIENES SOMOS
    {
        path: "/presentacion",
        name: "Presentación",
        component: function () {
            return import("../views/QuienesSomos/Presentacion.vue");
        },
    },

    {
        path: "/deteccion",
        name: "deteccion",
        component: function () {
            return import("../views/ManejoPlaga/deteccion.vue");
        },
    },

    {
        path: "/mision",
        name: "Misión",
        component: function () {
            return import("../views/QuienesSomos/Mision.vue");
        },
    },
    {
        path: "/valores",
        name: "Valores institucionales",
        component: function () {
            return import("../views/QuienesSomos/Valores.vue");
        },
    },
    {
        path: "/objetivos",
        name: "Objetivos",
        component: function () {
            return import("../views/QuienesSomos/Objetivos.vue");
        },
    },
    {
        path: "/organigrama",
        name: "Organigrama",
        component: function () {
            return import("../views/QuienesSomos/Organigrama.vue");
        },
    },
    {
        path: "/areatrabajo",
        name: "Áreas de trabajo",
        component: function () {
            return import("../views/QuienesSomos/AreasTrabajo.vue");
        },
    },
    {
        path: "/centrooperaciones",
        name: "Áreas de trabajo",
        component: function () {
            return import("../views/QuienesSomos/CentroOperaciones.vue");
        },
    },
    {
        path: "/areastrabajoregional",
        name: "Áreas de trabajo",
        component: function () {
            return import("../views/QuienesSomos/AreasTrabajoRegional.vue");
        },
    },
    {
        path: "/faqs",
        name: "Preguntas frecuentes",
        component: function () {
            return import("../views/QuienesSomos/Faqs.vue");
        },
    },
    {
        path: "/ley3698",
        name: "Ley3698",
        component: function () {
            return import("../views/QuienesSomos/Ley3698.vue");
        },
    },
    // MOSCAMED
    {
        path: "/biologia",
        name: "Biología de la mosca del Mediterráneo",
        component: function () {
            return import("../views/Moscamed/Biologia.vue");
        },
    },
    {
        path: "/dispersion",
        name: "Dispersión mundial",
        component: function () {
            return import("../views/Moscamed/Dispersion.vue");
        },
    },
    {
        path: "/dano",
        name: "Dañó que ocasiona",
        component: function () {
            return import("../views/Moscamed/Dano.vue");
        },
    },
    {
        path: "/importancia",
        name: "Importancia",
        component: function () {
            return import("../views/Moscamed/Importancia.vue");
        },
    },
    // MANEJO INTEGRADO DE LA PLAGA
    {
        path: "/trampeo",
        name: "Detección por trampeo",
        component: function () {
            return import("../views/ManejoPlaga/Trampeo.vue");
        },
    },
    {
        path: "/laboratorioadultos",
        name: "Laboratorio de identificación de adultos",
        component: function () {
            return import("../views/ManejoPlaga/LaboratorioAdultos.vue");
        },
    },
    {
        path: "/muestreo",
        name: "Detección por muestreo",
        component: function () {
            return import("../views/ManejoPlaga/Muestreo.vue");
        },
    },
    {
        path: "/laboratorioinmaduros",
        name: "Laboratorio de estados inmaduros",
        component: function () {
            return import("../views/ManejoPlaga/LaboratorioInmaduros.vue");
        },
    },
    {
        path: "/control",
        name: "Control",
        component: function () {
            return import("../views/ManejoPlaga/Control.vue");
        },
    },
    {
        path: "/aspersiones",
        name: "Aspersiones terrestres",
        component: function () {
            return import("../views/ManejoPlaga/Aspersiones.vue");
        },
    },
    {
        path: "/cebo",
        name: "Estaciones cebo",
        component: function () {
            return import("../views/ManejoPlaga/Cebo.vue");
        },
    },
    // {
    //     path: "/dispositivos",
    //     name: "Dispositivos de trampeo masivo con proteína hidrolizada",
    //     component: function () {
    //         return import("../views/ManejoPlaga/Dispositivos.vue");
    //     },
    // },
    {
        path: "/aspercionesaereas",
        name: "Aspersiones Aéreas",
        component: function () {
            return import("../views/ManejoPlaga/AspercionesAereas.vue");
        },
    },
    {
        path: "/producto",
        name: "Producto utilizado",
        component: function () {
            return import("../views/ManejoPlaga/Producto.vue");
        },
    },
    {
        path: "/certificaciones",
        name: "Certificaciones de producto",
        component: function () {
            return import("../views/ManejoPlaga/Certificaciones.vue");
        },
    },
    {
        path: "/licencia",
        name: "Licencia ambiental",
        component: function () {
            return import("../views/ManejoPlaga/Licencia.vue");
        },
    },
    {
        path: "/controlmecanico",
        name: "Control Mecánico",
        component: function () {
            return import("../views/ManejoPlaga/Mecanico.vue");
        },
    },
    {
        path: "/controlegal",
        name: "Control Legal",
        component: function () {
            return import("../views/ManejoPlaga/ControlLegal.vue");
        },
    },
    {
        path: "/frutos",
        name: "Frutos de cuarentena",
        component: function () {
            return import("../views/ManejoPlaga/Frutos.vue");
        },
    },
    {
        path: "/acuerdo191",
        name: "Acuerdo Ministerial 191-2007",
        component: function () {
            return import("../views/ManejoPlaga/Acuerdo191.vue");
        },
    },
    {
        path: "/controlautocida",
        name: "Control Autocida",
        component: function () {
            return import("../views/ManejoPlaga/ControlAutocida.vue");
        },
    },
    {
        path: "/produccion",
        name: "Producción mosca estéril",
        component: function () {
            return import("../views/ManejoPlaga/Produccion.vue");
        },
    },
    {
        path: "/certificado",
        name: "Certificado ISO Planta el Pino",
        component: function () {
            return import("../views/ManejoPlaga/Certificado.vue");
        },
    },
    {
        path: "/empaque",
        name: "Empaque y Liberación de insecto estéril",
        component: function () {
            return import("../views/ManejoPlaga/Empaque.vue");
        },
    },
    //COMUNICACION
    {
        path: "/apoyo",
        name: "Apoyo comunitario",
        component: function () {
            return import("../views/Comunicacion/Apoyo.vue");
        },
    },
    {
        path: "/notas",
        name: "Notas de prensa",
        component: function () {
            return import("../views/Comunicacion/Notas.vue");
        },
    },
    {
        path: "/centrotransferencia",
        name: "Centro de Transferencia de Tecnología Apícola",
        component: function () {
            return import("../views/Comunicacion/CentroTransferencia.vue");
        },
    },
    //RESULTADOS
    {
        path: "/areaslibres",
        name: "Centro de Transferencia de Tecnología Apícola",
        component: function () {
            return import("../views/Resultados/AreasLibres.vue");
        },
    },
    {
        path: "/reconocimientopeten",
        name: "Reconocimiento Peten",
        component: function () {
            return import("../views/Resultados/ReconocimientoPeten.vue");
        },
    },
    {
        path: "/declaratoriahuista",
        name: "Declaratoria Huista",
        component: function () {
            return import("../views/Resultados/DeclaratoriaHuista.vue");
        },
    },
    {
        path: "/declaratoriasalcaja",
        name: "Declaratoria Salcaja",
        component: function () {
            return import("../views/Resultados/DeclaratoriaSalcaja.vue");
        },
    },
    {
        path: "/declaratoriaocos",
        name: "Declaratoria Ocos",
        component: function () {
            return import("../views/Resultados/DeclaratoriaOcos.vue");
        },
    },
    {
        path: "/proteccion",
        name: "Protección de la fruticultura",
        component: function () {
            return import("../views/Resultados/Proteccion.vue");
        },
    },

    // {
    //     path: "/acercade",
    //     name: "Acerca de",
    //     component: function () {
    //         return import(/* webpackChunkName: "acercade" */ "../views/AcercaDe.vue");
    //     },
    // },
    // {
    //     path: "/quienessomos",
    //     name: "Quienes somos",
    //     component: function () {
    //         return import(/* webpackChunkName: "quienessomos" */ "../views/QuienesSomos.vue");
    //     },
    // },
    {
        path: "/informacionpublica",
        name: "Informacion Pública",
        component: function () {
            return import("../views/InfoPublica/InformacionPublica.vue");
        },
    },
    {
        path: "/centrocontacto",
        name: "Centro de contacto",
        component: function () {
            return import("../views/CentroContacto/CentroContacto.vue");
        },
    },
    {
        path: "/centrocontacto",
        name: "Centro de contacto",
        component: function () {
            return import("../views/CentroContacto/CentroContacto.vue");
        },
    },
    //NOTICIAS
    {
        path: "/noticias",
        name: "Noticias",
        component: function () {
            return import("../views/Noticias/Noticias.vue");
        },
    },
    {
        path: "/news-show/:slug",
        name: "News show",
        component: function () {
            return import("../views/Noticias/NewsShow.vue");
        },
    },
    //VIDEOS
    {
        path: "/videos",
        name: "Videos",
        component: function () {
            return import("../views/Videos/Videos.vue");
        },
    },
    //PODCAST
    {
        path: "/podcast",
        name: "Podcat",
        component: function () {
            return import("../views/Podcast/Podcast.vue");
        },
    },
    //PUBLICACIONES
    {
        path: "/publicaciones",
        name: "Publicaciones",
        component: function () {
            return import("../views/Publicaciones/Publicaciones.vue");
        },
    },
    //EVENTOS
    {
        path: "/eventos/invitacion/oferta",
        name: "Oferta",
        component: function () {
            return import("../views/Eventos/Invitacion/Oferta.vue");
        },
    },
    {
        path: "/eventos/invitacion/empleo",
        name: "Oferta",
        component: function () {
            return import("../views/Eventos/Invitacion/Empleo.vue");
        },
    },
    {
        path: "/eventos/actividades",
        name: "Oferta",
        component: function () {
            return import("../views/Eventos/Actividades/Actividades.vue");
        },
    },
    {
        path: "/eventos/actividades/comision",
        name: "Comision",
        component: function () {
            return import("../views/Eventos/Actividades/Comision.vue");
        },
    },
    {
        path: "/eventos/actividades/consejo",
        name: "Comision",
        component: function () {
            return import("../views/Eventos/Actividades/Consejo.vue");
        },
    },
    {
        path: "/eventos/actividades/cursos",
        name: "Comision",
        component: function () {
            return import("../views/Eventos/Actividades/Cursos.vue");
        },
    },
];

const router = new VueRouter({
    mode: "hash",
    base: process.env.BASE_URL,
    routes,
});

export default router;
