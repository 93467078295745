<template>
<div class="bg-gray algo">
    <div id="carouselExampleIndicators" class="carousel slide" data-ride="carousel">
        <ol class="carousel-indicators">
            <li data-target="#carouselExampleIndicators" data-slide-to="0" class="active"></li>
            <li data-target="#carouselExampleIndicators" data-slide-to="1"></li>
        </ol>
        <div class="carousel-inner">
            <div class="carousel-item active">
                <img class="d-block w-100" :src="require('@/assets/slider/slide1.jpg' )" alt="First slide">
                <div class="carousel-caption d-none d-md-block" style="text-shadow: 1px 1px 2px black;" >
                    <h3>MOSCA DEL MEDITERRÁNEO</h3>
                    <p>(<i>Ceratitis capitata</i> Wiedemann)</p>
                </div>
            </div>
            <div class="carousel-item">
                <img class="d-block w-100" :src="require('@/assets/slider/slide3.jpg' )" alt="First slide">
                <div class="carousel-caption d-none d-md-block" style="text-shadow: 1px 1px 2px black;">
                    <h3>PLANTA DE PRODUCCIÓN DE MOSCA DEL MEDITERRÁNEO ESTÉRIL</h3>
                </div>
            </div>
        </div>
        <a class="carousel-control-prev" href="#carouselExampleIndicators" role="button" data-slide="prev">
            <span class="carousel-control-prev-icon" aria-hidden="true"></span>
            <span class="sr-only">Previous</span>
        </a>
        <a class="carousel-control-next" href="#carouselExampleIndicators" role="button" data-slide="next">
            <span class="carousel-control-next-icon" aria-hidden="true"></span>
            <span class="sr-only">Next</span>
        </a>
    </div>
    <!-- <b-carousel
        id="carousel-1"
        :interval="4000"
        controls
        indicators
        background="#ababab"
        img-width="1500"
        img-height="600"
        style="text-shadow: 1px 1px 2px black;  p { color: red}"
    >
        <b-carousel-slide
            v-for="slide in slides"
            :key="slide.id"
            :caption="slide.caption"
            :text="slide.text"
            :img-src="slide.src"
        ></b-carousel-slide>
    </b-carousel><br> -->
  </div>
</template>
<script>
export default {
    data() {
        return {
            slides: [
                {
                    id: 1,
                    caption: "MOSCA DEL MEDITERRÁNEO ",
                    text: "(Ceratitis capitata Wiedemann)",
                    src: require("@/assets/slider/slide1.jpg"),
                },
                // {
                //     id: 2,
                //     caption: "",
                //     text: "PLANTA DE PRODUCCIÓN DE MOSCA DEL MEDITERRANEO ESTERIL",
                //     src: require("@/assets/slider/slide2.jpg"),
                // },
                {
                    id: 3,
                    caption: "PLANTA DE PRODUCCIÓN DE MOSCA DEL MEDITERRÁNEO ESTÉRIL",
                    text: "",
                    src: require("@/assets/slider/slide3.jpg"),
                },
            ],
        };
    },
};
</script>
<style>
    .bg-gray{
        background: #bfbfbf
    }
    
    .carousel-caption {
        z-index: 0 !important;
    }
</style>