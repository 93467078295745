<template>
    <div>
        <div class="bg-lightgray">
            <div class="row justify-content-center">
                <div class="col-sm-9">
                    <div class="row">
                        <div class="col-sm-2 text-center">
                            <a href="/"><img class="img-fluid mt-5 mt-sm-4 mb-3" src="../assets/logo.png" /></a>
                        </div>
                        <div class="col-sm-8 text-center mt-0 mt-sm-5 mb-0">
                            <strong style="font-size: 55px; color: #006617"
                                >PROGRAMA MOSCAMED <br />
                                GUATEMALA</strong
                            >
                        </div>
                        <div class="col-sm-2 w-100">
                            <div class="row mt-5 pb-4 pl-5">
                                <div class="col-4"></div>
                                <div class="col-8 mt-0 mt-sm-5">
                                    <div class="">Translate</div>
                                    <div
                                        v-on:click="toggleTranslate"
                                        v-bind:class="{ translate: isactive, 'translate-active': !isactive }"
                                    ></div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="redes">
                <a href="https://www.instagram.com/moscamedgt/" target="black">
                    <img class="img-fluid pr-4" src="@/assets/logos/instagram.png" />
                </a>
                <a href="https://www.facebook.com/Programa-MOSCAMED-Guatemala-1449883315279781" target="black">
                    <img class="img-fluid pr-4" src="@/assets/logos/facebook.png" />
                </a>
                <a href="https://www.youtube.com/@programamoscamedguatemala4315" target="black">
                    <img class="img-fluid pr-4" src="@/assets/logos/youtube.png" />
                </a>
                <a href="https://twitter.com/MOSCAMEDGT" target="black">
                    <img class="img-fluid pr-4" src="@/assets/logos/twitter.png" />
                </a>
                <a href="https://www.linkedin.com/company/10192921/admin/" target="black">
                    <img class="img-fluid pr-4" src="@/assets/logos/linkedin.png" />
                </a>
                <a
                    href="https://www.ivoox.com/podcast-deteccion-y-control-de-la-mosca-del-mediterraneo_sq_f11529658_1.html"
                    target="black"
                >
                    <img class="img-fluid pr-3" src="@/assets/logos/ivoox.png" />
                </a>
            </div>
        </div>
        <b-navbar class="row justify-content-center" toggleable="lg" type="dark" variant="primary" style="z-index: 1">
            <b-navbar-toggle target="nav-collapse"></b-navbar-toggle>
            <b-collapse class="col-lg-10 col-md-10 justify-content-center" id="nav-collapse" is-nav>
                <b-navbar-nav>
                    <b-nav-item-dropdown class="title" text="QUIÉNES SOMOS" selected>
                        <b-dropdown-item to="/presentacion">Presentación</b-dropdown-item>
                        <b-dropdown-item to="/mision">Misión y Visión</b-dropdown-item>
                        <b-dropdown-item to="/valores">Valores institucionales</b-dropdown-item>
                        <b-dropdown-item to="/objetivos">Objetivos</b-dropdown-item>
                        <b-dropdown-item to="/organigrama">Organigrama</b-dropdown-item>
                        <b-dropdown-item to="/areatrabajo">Áreas de trabajo</b-dropdown-item>
                        <b-dropdown-item to="/centrooperaciones">Centros de operaciones</b-dropdown-item>
                        <b-dropdown-item to="/areastrabajoregional">Área de trabajo regional</b-dropdown-item>
                        <b-dropdown-item to="/faqs">Preguntas frecuentes</b-dropdown-item>
                        <b-dropdown-item to="/ley3698">Ley de Sanidad Vegetal y Animal Decreto 36-98</b-dropdown-item>
                    </b-nav-item-dropdown>
                    <b-nav-item-dropdown class="title" text="MOSCAMED">
                        <!-- <b-dropdown-item to="medfly">Mosca del Mediterráneo</b-dropdown-item> -->
                        <b-dropdown-item to="/biologia"
                            >Biología de la mosca del Mediterráneo y su ciclo</b-dropdown-item
                        >
                        <b-dropdown-item to="/dispersion"
                            >Dispersión mundial y distribución de la plaga</b-dropdown-item
                        >
                        <b-dropdown-item to="/dano">Daño que ocasiona</b-dropdown-item>
                        <b-dropdown-item to="/importancia">Importancia económica</b-dropdown-item>
                    </b-nav-item-dropdown>
                    <b-nav-item-dropdown class="title" text="MANEJO INTEGRADO DE LA PLAGA">
                        <!-- <b-nav-text class="submenu">Manejo Integrado de la Plaga</b-nav-text><br> -->
                        <router-link class="item-important" to="/deteccion"><strong>Detección</strong></router-link>
                        <b-dropdown-item class="item" to="/trampeo">Detección por trampeo</b-dropdown-item>
                        <b-dropdown-item class="item" to="/laboratorioadultos"
                            >Laboratorio de identificación de adultos</b-dropdown-item
                        >
                        <b-dropdown-item class="item" to="/muestreo">Detección por muestreo</b-dropdown-item>
                        <b-dropdown-item class="item" to="/laboratorioinmaduros"
                            >Laboratorio de estados inmaduros</b-dropdown-item
                        >
                        <b-dropdown-item to="/control"><strong>Control</strong></b-dropdown-item>
                        <b-dropdown-item class="item" to="/aspersiones">Aspersiones terrestres</b-dropdown-item>
                        <b-dropdown-item class="item" to="/controlmecanico">Control Mecánico</b-dropdown-item>
                        <b-dropdown-item class="item" to="/cebo">Estaciones cebo</b-dropdown-item>
                        <b-dropdown-item class="item" to="/controlegal">Control Legal</b-dropdown-item>
                        <b-dropdown-item class="subitem" to="/frutos">Frutos de cuarentena</b-dropdown-item>
                        <b-dropdown-item class="subitem" to="/acuerdo191">Acuerdo Ministerial 191-2007</b-dropdown-item>
                        <!-- <b-dropdown-item class="item" to="/dispositivos">Dispositivos de trampeo masivo con proteína hidrolizada</b-dropdown-item> -->
                        <b-dropdown-item class="item" to="/aspercionesaereas">Aspersiones Aéreas</b-dropdown-item>
                        <b-dropdown-item class="subitem" to="/producto">Producto utilizado</b-dropdown-item>
                        <b-dropdown-item class="subitem" to="/certificaciones"
                            >Certificaciones de producto</b-dropdown-item
                        >
                        <b-dropdown-item class="subitem" to="/licencia">Licencia ambiental</b-dropdown-item>
                        <b-dropdown-item class="item" to="/controlautocida"
                            >Control Autocida – Técnica del Insecto Estéril (TIE)</b-dropdown-item
                        >
                        <b-dropdown-item class="subitem" to="/produccion">Producción mosca estéril</b-dropdown-item>
                        <b-dropdown-item class="subitem" to="/certificado"
                            >Certificado ISO Planta el Pino</b-dropdown-item
                        >
                        <b-dropdown-item class="subitem" to="/empaque"
                            >Empaque y Liberación de insecto estéril</b-dropdown-item
                        >
                    </b-nav-item-dropdown>
                    <b-nav-item-dropdown class="title" text="COMUNICACIÓN Y APICULTURA" selected>
                        <!-- <b-nav-text class="submenu"><strong>Comunicación Social</strong></b-nav-text> -->
                        <b-dropdown-item class="item" to="/apoyo">Comunicación</b-dropdown-item>
                        <b-dropdown-item class="item" to="notas">Notas de prensa</b-dropdown-item>
                        <!-- <b-nav-text class="submenu"><strong>Apoyo al Sector Apícola</strong></b-nav-text> -->
                        <b-dropdown-item class="item" to="/centrotransferencia"
                            >Centro de Transferencia de Tecnología Apícola</b-dropdown-item
                        >
                    </b-nav-item-dropdown>
                    <b-nav-item-dropdown class="title" text="RESULTADOS " selected>
                        <b-dropdown-item to="/areaslibres">Áreas libres</b-dropdown-item>
                        <b-dropdown-item class="item" to="/reconocimientopeten">Reconocimiento Petén</b-dropdown-item>
                        <b-dropdown-item class="item" to="/declaratoriahuista">Declaratoria los Huista</b-dropdown-item>
                        <b-dropdown-item class="item" to="/declaratoriasalcaja">Declaratoria Salcajá</b-dropdown-item>
                        <b-dropdown-item class="item" to="/declaratoriaocos"
                            >Declaratoria Ocos - Champerico</b-dropdown-item
                        >
                        <b-dropdown-item to="/proteccion">Protección de la fruticultura</b-dropdown-item>
                    </b-nav-item-dropdown>
                    <!-- <b-nav-item to="contactenos">CONTÁCTENOS</b-nav-item> -->
                </b-navbar-nav>
            </b-collapse>
        </b-navbar>
    </div>
</template>
<script>
export default {
    props: ["isactive"],
    data() {
        return {};
    },
    methods: {
        toggleTranslate() {
            this.$emit("translate", this.isactive);
        },
    },
};
</script>
<style scoped>
h1 {
    font-size: 23px;
    line-height: 100px;
}
.translate {
    height: 30px;
    width: 60px;
    background-color: rgb(100, 100, 100);
    border-radius: 50px;
    animation: toggleGreen 0.5s forwards;
}

.translate-active {
    height: 30px;
    width: 60px;
    background-color: rgb(35, 180, 30);
    border-radius: 50px;
    animation: toggleGray 0.5s forwards;
}

.translate:after {
    content: " ";
    position: absolute;
    width: 30px;
    height: 53%;
    background-color: white;
    border-radius: 100px;
    animation: toggleLeft 0.5s forwards;
}

.translate-active:after {
    content: " ";
    position: absolute;
    left: 45px;
    width: 30px;
    height: 53%;
    background-color: white;
    border-radius: 100px;
    animation: toggleRight 0.5s forwards;
}
.row {
    margin-right: 0px;
    margin-left: 0px;
}

@keyframes toggleRight {
    from {
        left: 15px;
    }
    to {
        left: 45px;
    }
}

@keyframes toggleLeft {
    from {
        left: 45px;
    }
    to {
        left: 15px;
    }
}

@keyframes toggleGreen {
    from {
        background-color: rgb(100, 100, 100);
    }
    to {
        background-color: rgb(35, 180, 30);
    }
}

@keyframes toggleGray {
    from {
        background-color: rgb(35, 180, 30);
    }
    to {
        background-color: rgb(100, 100, 100);
    }
}

.submenu {
    color: #000 !important;
}

.navbar-nav {
    font-size: 20px;
}

.submenu {
    padding-left: 25px !important;
}

.item {
    padding-left: 25px !important;
}

.item-important {
    padding-left: 25px !important;
    color: #000 !important;
}

.subitem {
    padding-left: 50px !important;
}

.title {
    font-weight: bold;
    /* font-style: italic; */
}

.redes {
    position: absolute;
    top: 5px;
    right: 5px;
}
</style>
