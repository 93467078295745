<template>
    <div class="mt-5" style="background: #bfbfbf; color: #006617;">
       <div class="row">
            <!-- <div class="col-sm-1">
                <img class="ml-3 mt-3 mb-3" src="../assets/logo.png" height="100" />
            </div> -->
            <div class="col-sm-12">
                <h2 class="text-center p-5 text-uppercase"><strong>{{title}}</strong></h2>
            </div>
       </div>
    </div>
</template>
<script>
export default {
    props: ["title"],
    data() {
        return {};
    },
};
</script>
